<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/usmon.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Усмон хўжа<br />(1878-1968)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Пўлатхўжайев Усмон хўжа (1878, Бухоро — 1968.28.7, Истанбул) —
            давлат ва жамоат арбоби. Бухородаги жадидчилик ҳаракати намояндаси.
            Бухоролик савдогар оиласида туғилган. Бухорода Мадраса таҳсилини
            олган ва дастлабки янги усул мактабларидан бирини очган. 1908 йилда
            Истанбулда ўқиган. 1912 й. бошларида У.Х. Бухорога қайтгач, "Тарбияи
            атфол" махфий ташкилотининг фаол аъзоларидан бирига айланган. 1917
            йилда У.Х. Ёш бухороликлар партияси МК таркибига киритилган. Колесов
            воқеасидан сўнг, Тошкентда Ёш бухороликларнинг сўл есерлар гуруҳини
            тузган. Ёш бухороликлар партияси Туркистон Марказий бюросининг
            аъзоси. Бухоро Халқ Совет Республикаси тузилгач, У.Х. БХСР молия
            нозири (1920), Давлат назорати нозири (1921), БХСР Марказий Ижроия
            Кўмитасининг дастлабки раиси (1921 йил август — 1922 йил бошлари).
            У.Х. БХСР ҳукуматининг аксарият арбоблари сингариБухоро
            Республикасининг ўз миллий армияси, миллий ҳукумати бўлган мустақил
            республикага айлантирмоқчи еди. У.Х. БХСРдан совет Россиясининг
            қизил аскарлари олиб кетилиши ҳамда Бухоронинг мустақиллиги учун
            изчил курашди. У.Х. 1922 йилдан шўролар билан алоқасини узиб,
            Кобулга келгач, Россияга қарши кураш олиб бориш учун БХСР нинг
            давлат бошлиги сифатида Aфғонистон амири Омонуллахон билан шартнома
            имзолаган (1922 й. 29 апрел). У Бухоронинг миллий армияси учун
            хорижий давлатлардан қуроляроғ сотиб олишга ҳаракат қилган. У.Х.
            Aнқарада Турк Култури (Турк Маданияти) институтини ташкил қилишда
            катта жонбозлик кўрсатган. Туркистон тарихига оид кўплаб асарлар,
            жумладан, "Туркистон" (Истанбул, 1936) рисоласининг муаллифи.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
